.custom-footer {
  position: fixed;
  border-top: 1px solid #1c295a;
  bottom: 0;
  height: 6rem;
  left: 0;
  right: 0;
  padding: 0 1rem !important;
  margin: 0 !important;
  z-index: 9000;
  background-color: #0f1733;
  & .level-item {
    padding: 1rem;
  }
}
