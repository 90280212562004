.fade-in-out {
  opacity: 0; /* Set initial opacity to 0 */
  animation: fade-in-out 4s cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: 1; /* Play the animation only once */
  position: relative;
  height: 100%;
  width: 100%;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in {
  //opacity: 0; /* Set initial opacity to 0 */
  animation: fade-in 1s cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: 1; /* Play the animation only once */
  position: relative;
  height: 100%;
  width: 100%;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
