.node {
}
.node-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.edge {
  stroke: #b1b1b7;
  stroke-dasharray: 5;
  animation: dashdraw 0.5s linear infinite;
  stroke-width: 1;
}
@keyframes dashdraw {
  0% {
    stroke-dashoffset: 10;
  }
}
