.circle-container {
  position: fixed;
  width: 2rem;
  z-index: 1600;
  height: 2rem;
  /*  width: 11rem;
  height: 11rem;
  transform: translate(-27rem, -13rem); */
  transition: all 1s ease-out;
  box-shadow: inset 0 0 1.5rem 0.1rem #276b997b, 0 0 0rem 0.1rem #2988c8;
  border-radius: 0.3rem;
  & > .animated-circle {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    position: absolute;
    box-shadow: inset 0 0 0rem 0rem #3498db, 0 0 0rem 0rem #3498db;
    box-shadow: inset 0 0 1.5rem 0.1rem #3498db, 0 0 0rem 0.2rem #3498db;
    transition: all 1s ease-out;
    animation: growCircle 0.1s ease-out forwards;
  }
}

@keyframes growCircle {
  from {
    width: 2rem;
    height: 2rem;
  }
  to {
    width: 11rem;
    height: 11rem;
    box-shadow: inset 0 0 1.5rem 0.1rem #3498db, 0 0 0rem 0.2rem #3498db;
  }
}

.iconer {
  width: 2rem;
  height: 2rem;
  /*  box-shadow: inset 0 0 1.5rem 0.1rem #db3434, 0 0 0rem 0.1rem #db3434; */
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  &.dim {
    opacity: 0.5;
  }
  &:hover,
  &.active {
    transform: scale(1.2);
    cursor: pointer;
    opacity: 1;
  }
}

.animation-start {
  width: 2rem;
  height: 2rem;
}

.edit-container {
  z-index: 1600;
}
