.float {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 100;
  /*  border: 1px solid #ccc; */
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .menu-item {
    padding: 1rem;
    display: flex;
    align-items: center; // Center items vertically
    color: #ccc;

    .icon {
      font-size: 1.5rem;
      margin-right: 0.5rem; // Add spacing between icon and label
    }

    .label {
      opacity: 0; // Initially hide the label
      transition: opacity 0.3s ease-in-out;
      font-weight: 600 !important;
    }

    &.is-active {
      color: #ffdb00;
      .label {
        opacity: 0; // Ensure the label is visible when the item is active
      }
    }

    &:hover {
      color: #ffdb00 !important;
      .label {
        opacity: 1 !important; // Show the label on hover
        color: #ffdb00;
      }
      /*   background: #000; */
    }
  }
}

.downmenu {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  z-index: 2000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
