/* This is workaround-code, really, but it works for now. I'll try to find a better solution later. */
.circle-hover {
  cursor: pointer !important;
  position: relative;
  width: 1rem;

  &:hover::after {
    content: "";
    background-color: #6eb96e28 !important;
    border-radius: 50%;
    position: absolute;
    width: 2rem; /* Double the width for the padding effect */
    height: 2rem; /* Double the height for the padding effect */
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 0.225rem), calc(-50% + 0.125rem)); /* Center the circle within the div  */
  }
}

.hour-register {
  cursor: cell !important;
  /*  z-index: 999; */
  text-align: left;
}

.hour-register.is-active {
  border: 1px solid rgb(73, 173, 102) !important;
  background: #6eb96e55 !important;
}

/* TODO: Rename, obviously */
.calendar-cell {
  min-height: calc((100vh - 26rem) / 5) !important; /* 15rem is the sum of the header and footer heights */
}

.calendar-item {
  &:nth-child(even) {
    background-color: #b2b0d3; /* Even index elements */
  }

  &:nth-child(odd) {
    background-color: #e2e2e2; /* Odd index elements */
  }
}

.calendar-item.is-active {
  border: 1px solid rgb(73, 173, 102);
  background: #6eb96e55;
}

@keyframes flash {
  from {
    background: white;
    transform: scale(1);
  }
  to {
    background: #6eb96e55;
    transform: scale(1.005);
  }
}

.calendar-item.flashing {
  animation: flash 0.2s 1;
}
