.nav-bar-logo {
  width: 100%;
  max-width: 3rem; // Jikanoke log had 5rem
  margin-left: 1.5rem;
}

.navbar-menu {
  margin-right: 1.5rem;
}

.navbar {
  z-index: 2000 !important;
}
