.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 1200;
  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    background-color: #333;
    color: #fff;
    font-size: 0.8rem;
    white-space: nowrap;
    border-radius: 4px;
    transition: visibility 0s, opacity 0.3s;
    z-index: 1200;

    &.up {
      transform: translate(-50%, -200%);
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}

//TODO: Consider using animation instead of states

/* .tooltip {
  // Initial styles for the tooltip
  .icon, .tooltip-text {
    display: none;
  }

  // Apply a transition effect for smooth visibility changes
  .icon, .tooltip-text {
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    // Show the tooltip on hover with a delay
    .icon, .tooltip-text {
      display: block;
    }

    // Adjust the opacity for a fade-in effect
    .icon {
      opacity: 1;
    }

    // You can control the timing of the tooltip appearance here
    .tooltip-text {
      opacity: 1;
      transition-delay: 0.2s; // Add a 200ms delay
    }
  }
} */
