.modal-layer {
  position: fixed;
  /*  background-color: #3739432a; */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* pointer-events: none; */
  z-index: 1201;
}

.hidden {
  display: none;
  opacity: 0;
}

.panel-menu {
  margin-top: 10px;
  padding: 10px;
  position: fixed;
  max-width: 400px;
  background-color: white;
  z-index: 1201 !important;
  border-style: solid;
  border-width: 0.05rem;
  border-color: #373943;
  pointer-events: all;

  &::before {
    content: "";
    position: absolute;

    top: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #373943 transparent;
    background-color: transparent;
  }

  &::after {
    content: "";
    position: absolute;
    top: -9px;
    left: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
  }
}
