$switch-input-height: 24px;
$switch-input-width: 50px;
$switch-label-background: grey;
$switch-label-handle-size: 20px;
$switch-label-handle-color: #fff;
$switch-label-handle-offset: 2px;
$switch-label-handle-active-size: 20px;
$switch-label-transition-duration: 0.3s;
$switch-label-checked-background: #00c48a;
.switch {
  display: inline-block;
  input[type="checkbox"] {
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: $switch-input-width;
    height: $switch-input-height;
    background: $switch-label-background;
    display: block;
    border-radius: $switch-input-height / 2;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: $switch-label-handle-offset;
    left: $switch-label-handle-offset;
    width: $switch-label-handle-size;
    height: $switch-label-handle-size;
    background: $switch-label-handle-color;
    border-radius: $switch-label-handle-size / 2;
    transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) $switch-label-transition-duration;
  }

  input:checked + label {
    background: $switch-label-checked-background;
  }

  input:checked + label:after {
    left: calc(100% - #{$switch-label-handle-offset});
    transform: translateX(calc(100% - #{$switch-label-handle-size * 2}));
  }

  label:active:after {
    width: $switch-label-handle-active-size;
  }
}

/* html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 420ms !important;
  transition-delay: 0 !important;
}
 */
